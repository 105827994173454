import styled from "styled-components"

type Props = {
  color: string,
  variable?: string
}

const Paragraph = styled.p<Props>`
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  a {
    color: ${props =>
      props.theme.variables.secondary[props?.color]
        ? props.theme.variables.secondary[props?.color]
        : props.theme.variables.secondary.mushroom};
  }
  color: ${props =>
    props.theme.variables.secondary[props?.color]
      ? props.theme.variables.secondary[props?.color]
      : props.theme.variables.secondary.mushroom};
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    font-size: 18px;
    line-height: 32px;
  }
`

export default Paragraph
