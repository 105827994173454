import React, { useState, useRef, ReactInstance } from "react";
import styled from "styled-components";
import PlayButtonIcon from "../../images/VideoButtonIcon.svg";
import Button from "../Commons/Button";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 200001,
    background: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  } as React.CSSProperties,
  content: {
    width: "80%",
    zIndex: 10,
    inset: "none",
    borderRadius: 0,
    background: "initial",
    border: "initial",
    position: "initial",
    overflow: "visible",
  } as React.CSSProperties,
};

const VideoWrapper = styled.div`
  position: relative;
  cursor: pointer;
  background: black;
  border: 4px solid ${(props) => props.theme.variables.primary.black};
  box-shadow: 8px 8px 0px ${(props) => props.theme.variables.primary.black};

  video {
    height: auto;
    opacity: 0.6;
    width: 100%;
    box-sizing: border-box;
  }
`;

const BannerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  display: block;
  transition: transform 0.4 ease-in-out;

  :hover {
    transform: translate(-50%, -50%) scale(1.05);
  }
`;

const PlayButton = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.variables.primary.white};
  margin: auto;
  width: 10vh;
  height: 10vh;
  max-height:100px;
  max-width:100px;

  // @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
  //   width: 77.47px;
  //   height: 77.47px;
  // }

  // @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
  //   width: 100.71px;
  //   height: 100.71px;
  // }

  // @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
  //   width: 128.68px;
  //   height: 128.68px;
  // }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    width: 10.42px;
    height: 10.42px;

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
      width: 16.72px;
      height: 16.72px;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
      width: 21.73px;
      height: 21.73px;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      width: 27.77px;
      height: 27.77px;
    }
  }
`;

const VideoSubTitle = styled.p`
  text-transform: uppercase;
  color: ${(props) => props.theme.variables.primary.white};
  text-align: center;
  letter-spacing: 0.15em;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  margin-top: 7.71px;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    font-size: 12px;
    line-height: 16px;
    margin-top: 12.52px;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: 16px;
  }
`;

const CloseIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.3554 5.6564L37.7052 5.30286L37.3536 4.95117L34.0488 1.64645L33.6953 1.29289L33.3417 1.64645L19.5732 15.4149L5.6564 1.64458L5.30286 1.29476L4.95117 1.64645L1.64645 4.95117L1.29476 5.30286L1.64458 5.6564L15.4149 19.5732L1.64645 33.3417L1.29289 33.6953L1.64645 34.0488L4.95117 37.3536L5.30286 37.7052L5.6564 37.3554L19.5732 23.5851L33.3417 37.3536L33.6953 37.7071L34.0488 37.3536L37.3536 34.0488L37.7071 33.6953L37.3536 33.3417L23.5851 19.5732L37.3554 5.6564Z"
        fill="#30353F"
        stroke="#30353F"
      />
    </svg>
  );
};

const ModalWrapper = styled.div`
  // display: flex;
`;

const ModalClose = styled.div`
  position: absolute;
  top: 32px;
  right: 24px;
  button {
    span {
      line-height: 0;
      padding: 12px 14px;
    }
  }
  // @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
  //   span {
  //     padding: 24px 26px;
  //   }
  // }
`;
const VideoArea = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  ::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

type VideoType = {
  src: string;
  title?: string;
  iframeLink?: string;
};

const Video = (props: VideoType) => {
  const { src, iframeLink } = props;
  const vidRef = useRef<HTMLVideoElement>(null);

  const [isModalStatus, setIsModalStatus] = useState(false);

  return (
    <>
      <VideoWrapper onClick={() => setIsModalStatus(true)}>
        <video autoPlay={true} muted loop ref={vidRef} src={src}></video>
        <BannerWrapper>
          <PlayButton>
            <PlayButtonIcon />
          </PlayButton>
          <VideoSubTitle>{props.title}</VideoSubTitle>
        </BannerWrapper>
      </VideoWrapper>

      {iframeLink && !!iframeLink.length && (
        <Modal isOpen={isModalStatus} style={customStyles} onRequestClose={() => setIsModalStatus(false)}>
          <ModalClose>
            <Button size="medium" type="secondary" onClick={() => setIsModalStatus(false)}>
              <CloseIcon />
            </Button>
          </ModalClose>
          <VideoArea>
            <iframe
              width="100%"
              height="100%"
              src={iframeLink}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </VideoArea>
        </Modal>
      )}
    </>
  );
};

export default Video;
